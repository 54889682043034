<template>
    <div class="productType">
        <div class="container">
            <div class="handle-box">
                <el-button type="primary" icon="el-icon-circle-plus-outline" class="handle-del mr10" @click="add">添加方案</el-button>
            </div>
            <el-table v-loading="loading" border :data="tableData" :header-cell-style="{backgroundColor:'#f5f7fa'}">
                <el-table-column align="center" prop="name" label="方案名称"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" icon="el-icon-edit" type="success" size="small">编辑</el-button>
                        <el-button @click="handleDel(scope.row)" icon="el-icon-edit" type="danger" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="page" :page-size="size" :total="total" @current-change="getList"></el-pagination>
            </div>
        </div>

        <Dialog ref="dialog" @success="getList" />
        <editDialog ref="editDialog" @success="getList" />
    </div>
</template>

<script>
    import Dialog from '@/components/PlanType/dialog'
    import editDialog from '@/components/PlanType/editDialog'
    export default {
        name: "PlanType",
        components:{
            Dialog,
            editDialog,
        },
        data(){
            return{
                tableData:[],
                loading:false,
                page:1,
                size:10,
                total:0,
            }
        },
        created(){
            this.getList();
        },
        methods:{
            //获取菜单数据
            async getList(page=1){
                try{
                    const {data} = await this.$axios({
                        method:'POST',
                        url:`/api/solution/show/${page}/${this.size}`,
                    });
                    this.loading = false;
                    if(data.code){
                        this.tableData = data.data;
                        this.total = data.count_num;
                    }
                }catch (e) {
                    //
                }
            },
            //添加菜单
            add(){
                this.$refs.dialog.open();
            },
            //修改菜单信息
            handleEdit(row){
                this.$refs.editDialog.open(row);
            },
            //删除菜单
            handleDel(row){
                // 二次确认删除
                this.$confirm("确定要删除吗？确定后该类别下面所有案例将清空", "提示", {type: "warning"})
                    .then(async () => {
                        const {data} = await this.$axios({
                            method:'POST',
                            url:'/api/solution/delete',
                            data:{
                                nid:row.id,
                            },
                        });
                        if(data.code){
                            this.$message({
                                message: data.msg,
                                type: 'success',
                            });
                            this.getList(1);
                        }else{
                            this.$message.error(data.msg);
                        }
                    })
                    .catch(() => {
                        //
                    });
            },
        },
    }
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }
</style>